.containerrExportReport {
    padding-top: 30px;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    white-space: pre-wrap;
}

.titler {
    font-size: 17px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
}

.tableContainer {
    border: 1px solid black;
}

.tableTr {
    border: 1px solid black;
}

.tableTh {
    border: 1px solid black;
}

.reported {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
    /* grid-column-gap: 20px;
    grid-row-gap: 20px; */
    justify-items: stretch;
    align-items: stretch;
    width: 100%;
}

@media print {
    .containerrExportReport {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      padding-left: 113.39px;
      padding-right: 56.69px;
      padding-top: 75.59px;
      padding-bottom: 75.59px;
    }
  }
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");
$warning: #f3a405;
$color-grey: #414446;
$medium-blue: #45629c;
$light-cyan: #6dbfd8;
$light-grey: #9d9898;
$hover-light: #f7f7f7;
* {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.trigger {
  padding: 25px;
  font-size: 18px;
  line-height: 50px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: rgba(0, 0, 0, 0.85);}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.logoHeader {
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.p-datatable-wrapper table {
  width: 100%;
}
.p-treetable-wrapper table {
  width: 100%;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
  padding: 0px;
}

.ant-breadcrumb {
  margin-top: 20px !important;
  margin-left: 35px !important;
}

.ant-layout-header {
  padding: 0px 0px !important;  
  .ant-dropdown-link {
    position: absolute;
  }
  a.list-inline-item {
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .list-inline-item {
    padding: 0 0.875rem;
    height: 50px;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    .avatar-text {
      margin-left: 0.5rem;
      color: #fff;
    }
  }  
}
.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon  {
  color: #103154;
  font-size: 1rem;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  color: #103154;
  font-size: 14px;
}
.ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
  color: #103154;
  font-size: 1rem;
}
.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
position: none;
}


.p-datatable, 
.p-treetable {
  font-size: 12px !important;
  text-align: center !important;
  tr:nth-child(even) {
    background: #f8f9fa !important;
  }
  .p-datatable-thead > tr:first-child > th,
  .p-treetable-thead > tr > th {
    padding-bottom: 0 !important;
    text-align: center !important;
    padding: 6px 6px 6px !important;
  }
  .p-datatable-tbody > tr > td,
  .p-treetable-tbody > tr > td {
    padding: 4px 1rem !important;
    cursor: pointer;
  }
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 10px;
  color: white !important;
} 
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 20px !important;
  height: 20px !important;
}
.p-checkbox .p-checkbox-box {
  width: 15px !important;
  height: 15px !important;
  margin-left: -5px;
}
.DataTable table > thead > tr > th,
.DataTable table > tbody,
.DataTable table > tbody > tr,
.DataTable table > tbody > tr > td {
    border: none !important;
}

.p-datatable {
    font-size: 13px !important;
    text-align: center !important;
    tr:nth-child(odd) {
      background: #f8f9fa !important;
      text-align: center !important;
    }
    .p-datatable-thead > tr:first-child > th {
      padding-bottom: 0 !important;
      text-align: center !important;
      border: 1px solid #ebeef0 !important;
      padding: 10px 10px 10px !important;
      background-color: #103154;
      color: white;
    }
  }
  .p-datatable .p-datatable-thead > tr:first-child > th{
  text-align: center !important;
  border-width: 0 1px 0px 0 !important;
  }

.p-treetable .p-sortable-column .p-sortable-column-icon {
  font-size: 10px;
} 
.p-treetable .p-treetable-tbody > tr > td .p-row-toggler,
.p-treetable .p-treetable-tbody > tr > td .p-row-editor-init,
.p-treetable .p-treetable-tbody > tr > td .p-row-editor-save,
.p-treetable .p-treetable-tbody > tr > td .p-row-editor-cancel {
  width: 20px !important;
  height: 20px !important;
}
.treetable table > thead > tr > th,
.treetable table > tbody,
.treetable table > tbody > tr,
.treetable table > tbody > tr > td {
    border: none !important;
}

.p-treetable {
    font-size: 13px !important;
    text-align: center !important;
    tr:nth-child(odd) {
      background: #f8f9fa !important;
      text-align: center !important;
    }
    .p-treetable-thead > tr:first-child > th {
      padding-bottom: 0 !important;
      text-align: center !important;
      border: 1px solid #ebeef0 !important;
      padding: 5px 5px 5px !important;
      background-color: #103154;
      color: white;
    }
  }
  .p-treetable .p-treetable-thead > tr:first-child > th{
  text-align: center !important;
  border-width: 0 1px 0px 0 !important;
  }
  
  .ant-input:hover {
    border-right-width: 1px !important;
  }

  /* input radius */
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-bottom-left-radius: 10px !important;
    height: 207px !important;
    border-top-left-radius: 10px !important;
  }
  /* button radius */
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 10px 10px 0 !important;
    height: 27px !important;
    background: whitesmoke !important;
  }
  .ant-btn-link {
    color: #000;
  }
  /* ariin ongo */
  .ant-input-group-addon {
    background-color: white !important;
  }
  
  .search-button .ant-input {
    background: whitesmoke !important;
  }
  .btn-layout {
    display: flex;
    flex-wrap: wrap;
    background: white !important;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 4px;
    button {
      align-items: center;
      display: flex;
      svg {
        font-size: 14px;
        color: $medium-blue;
        margin-right: 2px;
      }
      &:hover {
        background-color: $hover-light;
      }
    }
    .ant-btn[disabled] {
      color: #ccc !important;
      svg {
        color: #a8b6d3;
      }
    }
  }

.p-column-filter {
  width: 98%;
  float: left;
  height: 30px;
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
      display: none !important;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
      padding: .4rem;
      text-align: left;
      min-width: 55%;
      display: inline-block;
      margin: -.4em 1em -.4em -.4rem;
      font-weight: bold;
  }

  .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid var(--surface-d);
  }
}

.treetable-responsive-demo .p-treetable-responsive-demo .p-treetable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .treetable-responsive-demo .p-treetable.p-treetable-responsive-demo .p-treetable-thead > tr > th,
  .treetable-responsive-demo .p-treetable.p-treetable-responsive-demo .p-treetable-tfoot > tr > td {
      display: none !important;
  }

  .treetable-responsive-demo .p-treetable.p-treetable-responsive-demo .p-treetable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;
  }

  .treetable-responsive-demo .p-treetable.p-treetable-responsive-demo .p-treetable-tbody > tr > td .p-column-title {
      padding: .4rem;
      text-align: left;
      min-width: 55%;
      display: inline-block;
      margin: -.4em 1em -.4em -.4rem;
      font-weight: bold;
  }

  .treetable-responsive-demo .p-treetable.p-treetable-responsive-demo .p-treetable-tbody > tr > td:last-child {
      border-bottom: 1px solid var(--surface-d);
  }
}


.radioButton .ant-input-number-input {
  height: 20px;
}

.radioButton .ant-space-vertical {
  gap: 20px;
}

.site-layout-background .ant-layout-sider-trigger {
  background: #FFFFFF;
  color: #103154;
}

.selectArrow .ant-select-arrow {
  color:white;
}

.highcharts-legend .highcharts-no-tooltip {
  display: none;
}

.p-datatable.p-datatable-hoverable-rows
.p-datatable-tbody
> tr:not(.p-highlight):hover {
background: #e6f7ff !important;
}